<template>
  <div class="contents">
    <el-form
      ref="form"
      :model="form"
    >
      <el-card>
        <h1>櫻花LINE個人化綁定</h1>
        <h2>請填寫下列完成綁定</h2>
        <hr>
        <el-row>
          <el-col
            :xs="16"
            :sm="16"
            :md="16"
          >
            <el-form-item label="請輸入您的手機號碼">
              <el-input v-model="form.Mobile" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="8"
            :sm="8"
            :md="8"
          >
            <button
              type="button"
              class="_btn"
              :disabled="isVerifying"
              @click="submitLink()"
            >
              發送簡訊
            </button>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :xs="16"
            :sm="16"
            :md="16"
          >
            <el-form-item label="請輸入手機驗證碼">
              <el-input maxlength="6" v-model="form.Code" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="8"
            :sm="8"
            :md="8"
          >
            <button
              type="button"
              class="_btn"
              :disabled="!isSendSMS || isVerifying"
              @click="submitVerify()"
            >
              驗證
            </button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <!--交成功 -->
    <guide-two
      :is-dialog-show="isShowTwo"
      :no-show-click="noShowClick"
      :mody-type="modyType"
    />
    <!-- 異常 -->
    <guide-two
      :is-dialog-show="isShowTwo3"
      :no-show-click="
        () => {
          isShowTwo3 = false
        }
      "
      :mody-type="modyType"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import axios from 'axios'
import { post as ajaxPost } from './../plugins/ajax'
import storage from './../storage'
import {
  getCustomerByLineMid,
  getCustomerByMobile,
  custBinding
} from '../api/api'
import GuideTwo from '@/components/PopUp/Guide_2'
export default {
  components: {
    GuideTwo
  },
  data () {
    return {
      isShowTwo: false,
      isShowTwo3: false,
      isVerifying: false,
      isSendSMS: false,
      form: {
        Mobile: '',
        Code: ''
      },
      modyType: '0',
      CustId: ''
    }
  },
  async mounted () {
    await this.liffInit(process.env.VUE_APP_LIFFID_MEMBER_LINK_PHONE)
    const LINEMid = this.getUserLineId(this.$route)
    const res = _.get(await getCustomerByLineMid({ LINEMid }), 'data.Data')
    if (res) {
      storage.setItem('userName', res)
      // 已是CRM會員且綁定直接導至個人資訊頁
      this.modyType = '11'
      this.isShowTwo = true
    }
  },
  methods: {
    async submitLink () {
      this.isVerifying = true

      const submitData = {
        Mobile: this.form.Mobile,
        Source: '消費者登入'
      }
 
      const res = await ajaxPost(
        'https://crmreport.sakura.com.tw/API/WATERVIPSentSMS',
        submitData
      )
      if (res.State) {
        this.modyType = '12'
        this.isShowTwo = true
        this.isSendSMS = true;
      } else {
        this.modyType = '13'
        this.isShowTwo3 = true
      }
      this.isVerifying = false
    },
    async submitVerify () {
      this.isVerifying = true;

      const submitData = {
        Mobile: this.form.Mobile,
        Code: this.form.Code
      }

      console.log('checkCustomerExist')
      await this.checkCustomerExist()

      const res = await ajaxPost(
        'https://crmreport.sakura.com.tw/API/WATERVIPVerifySMS',
        submitData
      )
      console.log('res.State=>', res.State)

      if (res.State) {
        storage.setItem('CustMobile', this.form.Mobile)
        const url = 'https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/save-verify-phone'
        const LINEMid = this.getUserLineId(this.$route)
        const body = {
          uid: LINEMid,
          phone: this.form.Mobile
        }
        await axios.post(url, body)
        this.modyType = '14'
        this.isShowTwo = true
      } else {
        this.modyType = '15'
        this.isShowTwo3 = true
      }
      this.isVerifying = false
    },
    async checkCustomerExist () {
      const res = _.get(
        await getCustomerByMobile({ Mobile: this.form.Mobile }),
        'data.Data'
      )
      if (res?.CustId) {
        this.CustId = res.CustId
      }
    },
    async bindLineToCust () {
      const LINEMid = this.getUserLineId(this.$route)
      if (this.CustId !== '') {
        const bindingData = {
          CustId: this.CustId,
          Mobile: this.form.Mobile,
          LINEMid: LINEMid
        }
        const res = _.get(await custBinding(bindingData), 'data')
        if (res) {
          // 'userName' 是一個物件，前廠商命名規則真的很棒
          const userName = _.get(
            await getCustomerByLineMid({ LINEMid }),
            'data.Data'
          )
          storage.setItem('userName', userName)
          this.modyType = '10'
          this.isShowTwo = true
          // await this.kelakeCoupon(LINEMid)
        }
      } else {
        this.$router.push({
          path: '/customerService/memberLinkForm',
          query: { mid: LINEMid }
        })
      }
    },
    async noShowClick () {
      this.isShowTwo = false
      const LINEMid = this.getUserLineId(this.$route)
      switch (this.modyType) {
        case '10':
          this.$router.push({
            path: '/customerService/personal',
            query: { mid: LINEMid }
          })
          break
        case '11':
          this.$router.push({
            path: '/customerService/personal',
            query: { mid: LINEMid }
          })
          break
        case '12':
          // 因API getCustomerByMobile 執行時間較長，故發送簡訊時先偷跑檢查CRM是否有CustId, 待輸入驗證碼成功即可立即跳轉下一步
          break
        case '14':
          console.log('bindLineToCust')
          await this.bindLineToCust()
          break

        default:
      }
    }
    // kelake 優惠券活動
    // async kelakeCoupon (LINEMID) {
    //   const submitData = { site: process.env.VUE_APP_SITE, userId: LINEMID }
    //   await ajaxPost('https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/kelake/coupon/push', submitData)
    // }
  }
}
</script>

<style scoped lang="scss">
button._btn {
  border: 0;
  color: #fff1f1;
  background-color: #C8161D;
  border-radius: 5px;
  padding: 10px 20px;
  width: 95px;
  margin: 40px auto 0 auto;
  display: block;
}

button._btn[disabled] {
  background-color: #c5c5c5;
}
.addressCol {
  width: 50%;
  display: inline-block;
}
._btnSubmit {
  background: #C8161D;
  box-shadow: 0 2px 4px 0 rgba(35, 36, 38, 0.3);
  border-radius: 4px;
  border: 0px;
  color: white;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

._btnSubmit[disabled] {
  background-color: #c5c5c5;
}

h1 {
  padding: 15px 0;
  font-size: 18px;
  font-weight: 900;
  color: #C8161D;
}

h2 {
  padding: 12px 0;
  font-weight: 900;
  font-size: 16px;
}

h3 {
  padding: 10px 0;
  font-weight: 900;
  font-size: 14px;
}

.contents p {
  padding: 10px 0;
}

.contents {
  line-height: 22px;
}
@import '../assets/styles/mixin.scss';
.contents {
  .el-card {
    border-radius: 1rem;
  }
  @media screen and (max-width: 992px) {
    button._btn {
      margin-top: 20px;
    }
    .el-card {
      .el-row {
        .el-col:last-child {
          margin-top: 2rem;
          margin-bottom: 1.5rem;
          span {
            bottom: -2rem;
          }
        }
      }
    }
  }
}
</style>
